import firebase from 'firebase/compat/app';
import {
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  getFirestore,
  collection,
  query,
  where,
  getDoc,
  getDocs,
  limit,
  doc,
  orderBy,
  startAt,
  endAt,
  onSnapshot,
  serverTimestamp
} from 'firebase/firestore';
import { firebaseProject, storage } from 'src/lib/firebaseProject';
import axios from 'axios';

const db = getFirestore(firebaseProject);

const getSubcollection = async (doc) => {
  let data = doc.data();
  for (let key in data) {
    const item = data[key];
    if (typeof item === 'object' && item && item.parent) {
      await service(item.parent.id).get(item.id, async (itemData) => {
        data[key] = itemData;
      });
    }
  }

  return { id: doc.id, ...data };
};

const listRawElements = (queryResult) => queryResult.docs.map((doc, _) => ({ id: doc.id, ...doc.data() }));
const listElements = (queryResult) => {
  const elements = queryResult.docs.map(async (doc, _) => {
    return await getSubcollection(doc);
  });

  return Promise.all(elements);
};

const service = (collectionName) => {
  const dbRef = collection(db, collectionName);
  const storageRef = storage.ref();
  return {
    db: db,
    storage: () => storageRef,
    collection: () => dbRef,
    docRef: (id) => doc(db, collectionName, id),
    count: (onResult, onError = (_) => {}) => {
      const q = query(collection(db, collectionName));
      return getDocs(q)
        .then((documentSnapshot) => onResult(documentSnapshot.size))
        .catch(onError);
    },
    allRaw: (onResult, onError = (_) => {}) => {
      const q = query(collection(db, collectionName));
      return getDocs(q)
        .then(async (queryResult) => onResult(await listRawElements(queryResult)))
        .catch(onError);
    },
    all: (onResult, onError = (_) => {}) => {
      const q = query(collection(db, collectionName));
      return getDocs(q)
        .then(async (queryResult) => onResult(await listElements(queryResult)))
        .catch(onError);
    },
    get: (id, onResult, onError = (_) => {}) =>
      getDoc(doc(db, collectionName, id))
        .then(async (documentSnapshot) => {
          onResult(await getSubcollection(documentSnapshot));
        })
        .catch(onError),
    whereRaw: (fieldPath, opStr, value, onResult) => {
      const q = query(collection(db, collectionName), where(fieldPath, opStr, value));
      return getDocs(q).then(async (queryResult) => onResult(await listRawElements(queryResult)));
    },
    where: (fieldPath, opStr, value, onResult, limited = 5000) => {
      const q = query(collection(db, collectionName), where(fieldPath, opStr, value), limit(limited));
      return getDocs(q).then(async (queryResult) => onResult(await listElements(queryResult)));
    },
    whereActived: (fieldPath, opStr, value, onResult, onError) => {
      const q = query(collection(db, collectionName), where(fieldPath, opStr, value), where('isActive', '==', true));
      return getDocs(q)
        .then(async (queryResult) => onResult(await listElements(queryResult)))
        .catch(onError);
    },
    first: (limited = 1, onResult, onError = (_) => {}) => {
      const q = query(collection(db, collectionName), limit(limited));
      return getDocs(q)
        .then(async (queryResult) => onResult(await listElements(queryResult)))
        .catch(onError);
    },
    search: (fieldPath, value, onResult, limit = 5000) => {
      const q = query(
        collection(db, collectionName),
        orderBy(fieldPath),
        startAt(value),
        endAt(value + '\uf8ff'),
        limit(limited)
      );
      return getDocs(q)
        .then(async (queryResult) => onResult(await listElements(queryResult)))
        .catch(onError);
    },
    onSnapshot: (id, onResult) => {
      onSnapshot(doc(db, collectionName, id), async (documentSnapshot) => {
        onResult(await getSubcollection(documentSnapshot));
      });
    },
    set: (id, data, onSuccess, onError = (_) => {}) => {
      const timeStamp = serverTimestamp();
      setDoc(doc(db, collectionName, id), { createdAt: timeStamp, updatedAt: timeStamp, ...data })
        .then(onSuccess)
        .catch(onError);
    },
    add: (data, onSuccess, onError = (_) => {}) => {
      const timeStamp = serverTimestamp();
      return addDoc(collection(db, collectionName), {
        ...data,
        createdAt: timeStamp,
        updatedAt: timeStamp,
        isActive: true
      })
        .then(onSuccess)
        .catch(onError);
    },
    update: (id, data, onSuccess, onError = (_) => {}) => {
      const timeStamp = serverTimestamp();
      return updateDoc(doc(db, collectionName, id), { ...data, updatedAt: timeStamp })
        .then(onSuccess)
        .catch(onError);
    },
    active: (id, onSuccess = (_) => {}, onError = (_) => {}) => {
      const timeStamp = serverTimestamp();
      return updateDoc(doc(db, collectionName, id), { updatedAt: timeStamp, isActive: true })
        .then(onSuccess)
        .catch(onError);
    },
    close: (id, onSuccess = (_) => {}, onError = (_) => {}) => {
      const timeStamp = serverTimestamp();
      return updateDoc(doc(db, collectionName, id), { updatedAt: timeStamp, isActive: false })
        .then(onSuccess)
        .catch(onError);
    },
    delete: (id, onSuccess = (_) => {}, onError = (_) => {}) =>
      deleteDoc(doc(db, collectionName, id))
        .then(onSuccess)
        .catch(onError),
    downloadFile: (extension, endpoint, onDownloadProgress) => {
      return axios.get(endpoint, {
        responseType: 'blob',
        params: { extension: extension },
        onDownloadProgress: onDownloadProgress
      });
    }
  };
};

export default service;
