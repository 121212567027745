/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Link, List, ListSubheader, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import BuildIcon from '@mui/icons-material/Build';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { Briefcase as BriefcaseIcon, Calendar as CalendarIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import NavLeafItem from './NavLeafItem';
import { useTranslation } from 'react-i18next';
import {APP_VERSION} from "../../../constants";

function renderNavItems({ items, depth = 0 }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, depth }), [])}</List>;
}

function reduceChildRoutes({ acc, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    acc.push(
      <NavItem depth={depth} icon={item.icon} key={key} title={item.title} href={item.href}>
        {renderNavItems({
          depth: depth + 1,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(<NavLeafItem depth={depth} icon={item.icon} key={key} title={item.title} href={item.href} />);
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: '64px !important',
    height: 'calc(100% - 64px) !important'
  },
  avatar: {
    cursor: 'pointer',
    width: '64px !important',
    height: '64px !important'
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation();

  const allSections = [
    {
      subheader: 'Administración',
      items: [
        {
          title: t('users'),
          icon: PeopleAltIcon,
          href: '/app/users'
        },
        {
          title: t('orders'),
          icon: HomeWorkIcon,
          href: '/app/orders'
        },
        {
          title: t('works'),
          icon: BuildIcon,
          href: '/app/myWorks'
        },
        {
          title: t('clients'),
          icon: BriefcaseIcon,
          href: '/app/clients'
        },
        {
          title: t('calendar'),
          icon: CalendarIcon,
          href: '/app/calendar'
        },
        {
          title: t('materials'),
          icon: ChromeReaderModeIcon,
          href: '/app/materials'
        }
      ]
    }
  ];
  const sections = {
    ADMIN: allSections,
    MANAGER: allSections,
    OPERATOR: [
      {
        subheader: 'Operario',
        items: [
          {
            title: t('orders'),
            icon: HomeWorkIcon,
            href: '/app/orders'
          },
          {
            title: t('works'),
            icon: BuildIcon,
            href: '/app/myWorks'
          },
          {
            title: t('calendar'),
            icon: CalendarIcon,
            href: '/app/calendar'
          },
        ]
      }
    ]
  };

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link component={RouterLink} to="/app/account" variant="h5" color="textPrimary" underline="none">
              {user.name}
            </Link>
            {/*
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Your tier:
              {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link>
            </Typography>
            */}
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections[user.role].map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({ items: section.items })}
            </List>
          ))}
        </Box>
        <Divider />
        {
          <Box p={2}>
            <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
              <Typography variant="h6" color="textPrimary">
                TECNOMAX C&A S.L. B72153596
                <br />
                Pol. Ind, C/ Forjadores 5
                <br />
                11130 Chiclana de la Fra. (Cádiz)
              </Typography>
              <Link variant="subtitle1" color="secondary" component={RouterLink} to="/version">
                Versión {APP_VERSION}
              </Link>
              <Typography variant="subtitle2" color="textPrimary">
                No hay nuevas actualizaciones
              </Typography>
            </Box>
          </Box>
        }
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
