import firebase from 'firebase/compat/app';
import { collection, query, where, getDocs, orderBy, limit, or, and } from 'firebase/firestore';

import firestoreServices from 'src/services/firestore.service';
import clientsServices from 'src/services/clients.services';
import ordersServices from 'src/services/orders.services';
import specialtiesServices from 'src/services/specialties.services';
import operatorsServices from 'src/services/users.services';
import { COMPLETED, OPEN, PENDING } from 'src/constants';

const collectionName = 'works';
const worksFirestoreServices = firestoreServices(collectionName);

const getDuration = (startDate, endDate) => {
  let duration = [];
  for (
    let min = startDate.getMinutes() + 15, hour = startDate.getHours();
    min < endDate.getMinutes() || hour <= endDate.getHours() - 1;
    min = min + 15
  ) {
    if (min === 60) {
      min = 0;
      hour = hour + 1;
    }
    startDate.setMinutes(min);
    startDate.setHours(hour);
    if (!(startDate.getMinutes() === endDate.getMinutes() && startDate.getHours() === endDate.getHours()))
      duration.push(firebase.firestore.Timestamp.fromDate(startDate));
  }
  return duration;
};

const getSubcollection = async doc => {
  let data = doc.data();
  for (let key in data) {
    const item = data[key];
    if (typeof item === 'object' && item && item.parent) {
      await firestoreServices(item.parent.id).get(item.id, async itemData => {
        data[key] = itemData;
      });
    }
  }
  return { id: doc.id, ...data };
};

const listElements = queryResult => {
  const elements = queryResult.docs.map(async (doc, _) => {
    return await getSubcollection(doc);
  });

  return Promise.all(elements);
};

export default {
  ...worksFirestoreServices,
  add: (data, onSuccess, onError = _ => {}) => {
    let duration = getDuration(new Date(data.start), new Date(data.end));
    if (typeof data.orderId === 'string' || data.orderId instanceof String) {
      data.order = ordersServices.docRef(data.orderId);
    }
    if (typeof data.clientId === 'string' || data.clientId instanceof String) {
      data.client = clientsServices.docRef(data.clientId);
    }
    if (typeof data.specialtyId === 'string' || data.specialtyId instanceof String) {
      data.specialty = specialtiesServices.docRef(data.specialtyId);
    }
    if (typeof data.operatorId === 'string' || data.operatorId instanceof String) {
      data.operator = operatorsServices.docRef(data.operatorId);
    }
    if (data.scheduledAt !== undefined) {
      data.scheduledAt = firebase.firestore.Timestamp.fromDate(new Date(data.scheduledAt));
    }

    data.start = firebase.firestore.Timestamp.fromDate(new Date(data.start));
    data.end = firebase.firestore.Timestamp.fromDate(new Date(data.end));
    data.isActive = true;
    data.status = OPEN;

    return worksFirestoreServices.add(
      {
        duration: duration,
        ...data
      },
      onSuccess,
      onError
    );
  },
  update: (id, data, onSuccess, onError = _ => {}) => {
    let duration = getDuration(new Date(data.start), new Date(data.end));
    if (data.start) {
      data.start = firebase.firestore.Timestamp.fromDate(new Date(data.start));
    }
    if (data.end) {
      data.end = firebase.firestore.Timestamp.fromDate(new Date(data.end));
    }
    if (typeof data.clientId === 'string' || data.clientId instanceof String) {
      data.client = clientsServices.docRef(data.clientId);
    }
    if (typeof data.orderId === 'string' || data.orderId instanceof String) {
      data.order = ordersServices.docRef(data.orderId);
    }
    if (typeof data.specialtyId === 'string' || data.specialtyId instanceof String) {
      data.specialty = specialtiesServices.docRef(data.specialtyId);
    }
    if (typeof data.operatorId === 'string' || data.operatorId instanceof String) {
      data.operator = operatorsServices.docRef(data.operatorId);
    }
    return worksFirestoreServices.update(
      id,
      {
        duration: duration,
        ...data
      },
      onSuccess,
      onError
    );
  },
  withNoStatus: (orderId, status, onResult, onError) => {
    const q = query(
      collection(worksFirestoreServices.db, collectionName),
      where('orderId', '==', orderId),
      where('status', '!=', status)
    );
    return getDocs(q)
      .then(async queryResult => onResult(await listElements(queryResult)))
      .catch(onError);
  },
  getAll: (onResult, onError = _ => {}) => {
    const q = query(collection(worksFirestoreServices.db, collectionName), orderBy('start', 'asc'));
    return getDocs(q)
      .then(async queryResult => onResult(await listElements(queryResult)))
      .catch(onError);
  },
  getOperator: (operatorId, onResult, onError = _ => {}) => {
    const q = query(
      collection(worksFirestoreServices.db, collectionName),
      or(
        where('operatorId', '==', operatorId),
        where('otherOperatorsId', 'array-contains', operatorId),
      ),
      orderBy('start', 'asc')
    );
    return getDocs(q)
      .then(async queryResult => onResult(await listElements(queryResult)))
      .catch(onError);
  },
  getLastOpenOperator: (operatorId, onResult, onError) => {
    const q = query(
      collection(worksFirestoreServices.db, collectionName),
      and(
        or(
          where('operatorId', '==', operatorId),
          where('otherOperatorsId', 'array-contains', operatorId),
        ),
        where('status', '==', OPEN)),
      orderBy('start', 'asc'),
      limit(1)
    );
    return getDocs(q)
      .then(async queryResult => onResult(await listElements(queryResult)))
      .catch(onError);
  },
  calendarQueryOrdered: (orderId, operatorId, dateGt, dateLt, onResult, onError) => {
    let q = query(
      collection(worksFirestoreServices.db, collectionName),
      where('orderId', '==', orderId),
      where('start', '>=', dateGt),
      where('start', '<=', dateLt),
      orderBy('start', 'asc')
    );
    if (operatorId) {
      q = query(
        collection(worksFirestoreServices.db, collectionName),
        and(where('orderId', '==', orderId),
        where('start', '>=', dateGt),
        where('start', '<=', dateLt),
        or(
          where('otherOperatorsId', 'array-contains', operatorId),
          where('operatorId', '==', operatorId)
        )),
        orderBy('start', 'asc')
      );
    }
    return getDocs(q).then(async queryResult => onResult(await listElements(queryResult)));
  },
  calendarQueryAll: (operatorId, dateGt, dateLt, onResult, onError = _ => {}) => {
    let q = query(
      collection(worksFirestoreServices.db, collectionName),
      where('start', '>=', dateGt),
      where('start', '<=', dateLt),
      where('status', 'in', [OPEN, COMPLETED, PENDING])
    );
    if (operatorId) {
      q = query(
        collection(worksFirestoreServices.db, collectionName),
        and(where('start', '>=', dateGt),
        where('start', '<=', dateLt),
        where('status', 'in', [OPEN, COMPLETED, PENDING]),
        or(
          where('otherOperatorsId', 'array-contains', operatorId),
          where('operatorId', '==', operatorId)
        ))
      );
    }
    return getDocs(q).then(async queryResult => onResult(await listElements(queryResult)));
  },
  byOperatorAndDatetime: (startDate, endDate, operatorId, workId = null, onResult, onError) => {
    startDate = firebase.firestore.Timestamp.fromDate(new Date(startDate));
    endDate = firebase.firestore.Timestamp.fromDate(new Date(endDate));
    let results = [];
    const addResults = (r = []) => {
      results = results + r.filter(r => r.status !== 'CANCELED' && r.id !== workId);
    };
    const getQuery = (field, startCond, endCond) => {
      const q = query(
        collection(worksFirestoreServices.db, collectionName),
        and(
          or(
            where('operatorId', '==', operatorId),
            where('otherOperatorsId', 'array-contains', operatorId),
          ),
        where(field, startCond, startDate),
        where(field, endCond, endDate))
      );
      return getDocs(q)
        .then(async queryResult => addResults(await listElements(queryResult)))
        .catch(onError);
    };
    const getDurationQuery = date => {
      const q = query(
        collection(worksFirestoreServices.db, collectionName),
        where('operatorId', '==', operatorId),
        where('duration', 'array-contains', date)
      );
      return getDocs(q).then(async queryResult => addResults(await listElements(queryResult)));
    };
    return Promise.all([
      getQuery('start', '>=', '<'),
      getQuery('end', '>', '<='),
      getDurationQuery(startDate) // Case when one time range is inside another.
      // Firestore doesn't allow query by >, < on different fields. :(
    ]).then(async () => await onResult(results));
  },
  getLastStartedOrder: (orderId, workStart, onResult, onError) => {
    const q = query(
      collection(worksFirestoreServices.db, collectionName),
      where('orderId', '==', orderId),
      where('start', '<', workStart),
      orderBy('start', 'desc'),
      limit(1)
    );
    return getDocs(q)
      .then(async queryResult => onResult(await listElements(queryResult)))
      .catch(onError);
  }
};
