import React, { createContext, useEffect, useReducer } from 'react';
import SplashScreen from 'src/components/SplashScreen';
import usersServices from 'src/services/users.services';
import {
  getAuth,
  signInWithEmailAndPassword as signIn,
  createUserWithEmailAndPassword as createUser,
  sendSignInLinkToEmail as sendSignInLink,
  isSignInWithEmailLink as isSignInWithEmail,
  signInWithEmailLink as signInWithEmail,
  sendPasswordResetEmail as passwordReset,
  confirmPasswordReset as confirmPassword,
  signOut,
  onAuthStateChanged
} from 'firebase/auth';
import { firebaseProject } from 'src/lib/firebaseProject';

const auth = getAuth(firebaseProject);
const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'UPDATE_USER': {
      return {
        ...state,
        user: { ...state.user, ...action.payload }
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  sendSignInLinkToEmail: () => Promise.resolve(),
  fetchSignInMethodsForEmail: () => Promise.resolve(),
  isSignInWithEmailLink: () => Promise.resolve(),
  signInWithEmailLink: () => Promise.resolve(),
  sendPasswordResetEmail: () => Promise.resolve(),
  confirmPasswordReset: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  sendEmailVerification: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  deleteUser: () => Promise.resolve(),
  reauthenticateWithCredential: () => Promise.resolve(),
  getIdToken: () => Promise.resolve(),
  updateUser: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmailAndPassword = (email, password) => {
    return signIn(auth, email, password);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return createUser(auth, email, password);
  };

  const sendSignInLinkToEmail = async email => {
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_HOSTNAME}/register`,
      handleCodeInApp: true
    };

    return sendSignInLink(auth, email, actionCodeSettings);
  };

  const fetchSignInMethodsForEmail = async email => fetchSignInMethodsForEmail(auth, email);
  const isSignInWithEmailLink = href => {
    return isSignInWithEmail(auth, href);
  };

  const signInWithEmailLink = (email, href) => {
    return signInWithEmail(auth, email, href);
  };

  const sendPasswordResetEmail = async email => {
    const actionCodeSettings = {
      url: `${process.env.REACT_APP_HOSTNAME}/login`,
      handleCodeInApp: true
    };

    return passwordReset(auth, email, actionCodeSettings);
  };

  const confirmPasswordReset = async (code, email) => {
    return confirmPassword(auth, code, email);
  };

  const logout = () => {
    return signOut(auth);
  };

  const sendEmailVerification = () => {
    const user = auth.currentUser;
    return user.sendEmailVerification();
  };

  const updatePassword = newPassword => {
    const user = auth.currentUser;
    return user.updatePassword(newPassword);
  };

  const deleteUser = () => {
    const user = auth.currentUser;
    return user.delete();
  };

  const reauthenticateWithCredential = user => {
    const credential = firebaseProject.auth.EmailAuthProvider.credential(user.email, 'reauthenticateWithCredential');

    return user.reauthenticateWithCredential(credential);
  };

  const getIdToken = () => {
    return auth.currentUser.getIdToken();
  };

  const updateUser = async userData => {
    delete userData.roles;
    delete userData.rol;
    usersServices.update(state.user.id, userData, () => {
      dispatch({
        type: 'UPDATE_USER',
        payload: userData
      });
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        // Get User data, only one unique email
        usersServices.where('email', '==', user.email, results => {
          let userData = [];
          results.forEach(doc => {
            userData = doc;
          });
          dispatch({
            type: 'AUTH_STATE_CHANGED',
            payload: {
              isAuthenticated: true,
              user: {
                //...user,
                ...userData,
                avatar: user.photoURL,
                email: user.email,
                name: user.displayName || user.email,
                role: userData?.rol?.name
              }
            }
          });
        });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        sendSignInLinkToEmail,
        fetchSignInMethodsForEmail,
        isSignInWithEmailLink,
        signInWithEmailLink,
        sendPasswordResetEmail,
        confirmPasswordReset,
        logout,
        sendEmailVerification,
        updatePassword,
        deleteUser,
        reauthenticateWithCredential,
        getIdToken,
        updateUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
