import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>{route.routes ? renderRoutes(route.routes) : <Component {...props} />}</Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/users',
        component: lazy(() => import('src/views/user/ListView'))
      },
      {
        exact: true,
        path: '/app/orders',
        component: lazy(() => import('src/views/orders/ListView'))
      },
      {
        exact: true,
        path: '/app/orders/create',
        component: lazy(() => import('src/views/orders/CreateView'))
      },
      {
        exact: true,
        path: '/app/orders/:orderId/edit',
        component: lazy(() => import('src/views/orders/EditView'))
      },
      {
        exact: true,
        path: '/app/orders/:orderId/works',
        component: lazy(() => import('src/views/works/ListView'))
      },
      {
        exact: true,
        path: '/app/orders/:orderId/works/create',
        component: lazy(() => import('src/views/works/CreateView'))
      },
      {
        exact: true,
        path: '/app/orders/:orderId/works/:workId/edit',
        component: lazy(() => import('src/views/works/EditView'))
      },
      {
        exact: true,
        path: '/app/orders/:orderId/works/:workId',
        component: lazy(() => import('src/views/works/ShowView'))
      },
      {
        exact: true,
        path: '/app/orders/:orderId/works/:workId/signature',
        component: lazy(() => import('src/views/works/ShowView/Overview/Signature/CanvasSignature'))
      },
      {
        exact: true,
        path: '/app/myWorks',
        component: lazy(() => import('src/views/myWorks/ListView'))
      },
      {
        exact: true,
        path: '/app/users/create',
        component: lazy(() => import('src/views/user/CreateView'))
      },
      {
        exact: true,
        path: '/app/users/:userId',
        component: lazy(() => import('src/views/user/DetailsView'))
      },
      {
        exact: true,
        path: '/app/users/:userId/edit',
        component: lazy(() => import('src/views/user/EditView'))
      },
      {
        exact: true,
        path: '/app/clients',
        component: lazy(() => import('src/views/clients/ListView'))
      },
      {
        exact: true,
        path: '/app/clients/create',
        component: lazy(() => import('src/views/clients/CreateView'))
      },
      {
        exact: true,
        path: '/app/clients/:clientId/edit',
        component: lazy(() => import('src/views/clients/EditView'))
      },
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/calendar',
        component: lazy(() => import('src/views/calendar/CalendarView'))
      },
      {
        exact: true,
        path: '/app/materials',
        component: lazy(() => import('src/views/materials/ListView'))
      },
      {
        exact: true,
        path: '/app/materials/create',
        component: lazy(() => import('src/views/materials/CreateView'))
      },
      {
        exact: true,
        path: '/app/materials/createlist',
        component: lazy(() => import('src/views/materials/CreateListView'))
      },
      {
        exact: true,
        path: '/app/materials/:materialId/edit',
        component: lazy(() => import('src/views/materials/EditView'))
      },
      {
        exact: true,
        path: '/app/suppliers/createlist',
        component: lazy(() => import('src/views/suppliers/CreateListView'))
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/myWorks" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/version',
        component: lazy(() => import('src/views/Version'))
      },
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/myWorks" />
        //component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
