import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

import { firebaseConfig } from 'src/config';

let firebaseTempProject;
if (!firebase.apps.length) {
  firebaseTempProject = firebase.initializeApp(firebaseConfig, {
    // localCache: persistentLocalCache(/*settings*/ { tabManager: persistentMultipleTabManager() }),
    cacheSizeBytes: firebase.CACHE_SIZE_UNLIMITED
  });
}

export const firebaseProject = firebaseTempProject;
export const firestore = firebaseProject.firestore();
export const storage = firebaseProject.storage(`gs://${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`);
export const functions = firebaseProject.functions();
/*
import { initializeApp } from 'firebase/app';
export const firebaseProject = initializeApp(firebaseConfig, {
  // localCache: persistentLocalCache({ tabManager: persistentMultipleTabManager() }),
  cacheSizeBytes: firebase.CACHE_SIZE_UNLIMITED
});
*/
