import React, { useState } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Collapse, ListItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  active: {
    '& span': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium
    },
    '& svg': {
      color: theme.palette.secondary.main
    }
  }
}));

const NavItem = ({ children, depth, href, icon: Icon, title }) => {
  const classes = useStyles();
  const location = useLocation();
  const treeSelected = Boolean(matchPath(location.pathname, { path: href, exact: false }));
  const [open, setOpen] = useState(treeSelected);

  let paddingLeft = 2 + 2 * depth;

  return (
    <>
      <ListItem disablePadding disableGutters key={title}>
        <ListItemButton
          className={treeSelected ? classes.active : ''}
          sx={{ paddingLeft }}
          onClick={() => setOpen(prevOpen => !prevOpen)}
        >
          {Icon && (
            <ListItemIcon sx={{ minWidth: 35 }}>
              <Icon size="20" />
            </ListItemIcon>
          )}
          <ListItemText primary={title} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
      </ListItem>
      <Collapse in={open}>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
