import React from 'react';
import { Typography } from '@mui/material';

const Logo = props => {
  return (
    <>
      <img alt="Logo" src="/static/logo/bytakora_logo_blanco.png" height={40} {...props} />
    </>
  );
};

export default Logo;
