import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Bell as BellIcon } from 'react-feather';
import useAuth from 'src/hooks/useAuth';
import { getToken, receiveMessage } from 'src/services/pushNotifications.services';
import notificationsHistoryServices from 'src/services/notificationsHistory.services';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';

const useStyles = makeStyles(theme => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  }
}));

const Notifications = () => {
  const classes = useStyles();
  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState(null);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { user } = useAuth();
  const [isNew, setIsNew] = useState('standard');

  const getNotifications = useCallback(async () => {
    await notificationsHistoryServices.get(user.id, fbNotifications => {
      let read = true;
      let array = [];
      fbNotifications
        .sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
        .map(doc => {
          array.push({ id: doc.id, ...doc.data() });
          read = read && doc.data().usersRead.indexOf(user.id) >= 0;
        });
      setNotifications(array);
      setIsNew(read ? 'standard' : 'dot');
    });
  }, []);

  useEffect(() => {
    getToken(user.id);
    getNotifications();
    receiveMessage(n => setNewNotification(n));
  }, []);

  useEffect(() => {
    if (newNotification) {
      setIsNew('dot');
      setNotifications([newNotification, ...notifications]);
    }
  }, [newNotification]);

  const notificationsList = () => {
    return notifications.length === 0 ? (
      <Box p={2}>
        <Typography variant="h6" color="textPrimary">
          No hay notificaciones que mostrar
        </Typography>
      </Box>
    ) : (
      <>
        <List style={{ maxHeight: 360, overflow: 'auto' }}>
          {notifications.map(notification => {
            return (
              <ListItem
                //component={RouterLink}
                divider
                key={notification.id}
                to="#"
              >
                <ListItemAvatar onClick={() => markAsRead(notification)}>
                  <CreateNewFolderIcon
                    color={notification.usersRead?.indexOf(user.id) >= 0 ? 'inherit' : 'secondary'}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={notification.bodyMessage?.title}
                  primaryTypographyProps={{
                    variant: 'subtitle2',
                    color: 'textPrimary'
                  }}
                  secondary={notification.bodyMessage?.body}
                  onClick={() => markAsRead(notification)}
                />
                <IconButton onClick={() => deleteNotification(notification)}>
                  <DeleteOutlineIcon />
                </IconButton>
              </ListItem>
            );
          })}
        </List>
        <Box p={1} display="flex" justifyContent="center">
          <Button onClick={markAllAsRead} size="small">
            Marcar todas como leídas
          </Button>
        </Box>
      </>
    );
  };

  const deleteNotification = notification => {
    let array = [];
    for (let index = 0; index < notifications.length; index++) {
      if (notifications[index].id != notification.id) array.push(notifications[index]);
    }
    let aux = notification.usersNotRead.splice(notification.usersNotRead.indexOf(user.id), 1);
    setNotifications(array);
    notificationsHistoryServices.deleteNotification(notification.id, {
      usersNotRead: notification.usersNotRead.splice(notification.usersNotRead.indexOf(user.id), 1)
    });
  };

  const markAsRead = readNot => {
    let read = true;
    setNotifications(
      notifications.map(n => {
        if (n.id === readNot.id && n.usersRead.indexOf(user.id) < 0) {
          readNot.usersRead.push(user.id);
          notificationsHistoryServices.setRead(readNot.id, {
            usersRead: readNot.usersRead
          });
        }
        read = read && n.usersRead.indexOf(user.id) >= 0;
        return n;
      })
    );
    setIsNew(read ? 'standard' : 'dot');
  };

  const markAllAsRead = () => {
    setIsNew('standard');
    setNotifications(
      notifications.map(notification => {
        if (notification.usersRead?.indexOf(user.id) < 0) {
          notification.usersRead.push(user.id);
          notificationsHistoryServices.setRead(notification.id, {
            usersRead: notification.usersRead
          });
        }
        return notification;
      })
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <Badge color="secondary" variant={isNew} classes={{ badge: classes.badge }}>
          <IconButton color="inherit" ref={ref} onClick={handleOpen}>
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            Notificaciones
          </Typography>
        </Box>
        {notificationsList()}
      </Popover>
    </>
  );
};

export default Notifications;
