import firestoreServices from './firestore.service';
import { collection, query, where, getDocs } from 'firebase/firestore';

const collectionName = 'userNotifications';
const userNotificationsFirestoreServices = firestoreServices(collectionName);

export default {
  ...userNotificationsFirestoreServices,
  where: (fieldPath, opStr, value, onResult) => {
    const q = query(collection(userNotificationsFirestoreServices.db, collectionName), where(fieldPath, opStr, value));
    return getDocs(q).then(async queryResult => onResult(queryResult));
  }
};
