import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import worksServices from 'src/services/works.services';
import { colors } from '@mui/material';
import React from 'react';

const initialState = {
  events: [],
  isModalOpen: false,
  selectedEventId: null,
  selectedRange: null,
  viewedRange: null,
  appliedFilter: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state, action) {
      const { events } = action.payload;

      state.events = events;
    },
    createEvent(state, action) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    selectEvent(state, action) {
      const { eventId = null } = action.payload;

      state.isModalOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state, action) {
      const { event } = action.payload;

      state.events = _.map(state.events, _event => {
        if (_event?.id === action.payload?.id) {
          return formatEvent(action.payload);
        }
        return _event;
      });
    },
    deleteEvent(state, action) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(state, action) {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openModal(state) {
      state.isModalOpen = true;
    },
    closeModal(state) {
      state.isModalOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    viewRange(state, action) {
      const { start, end } = action.payload;
      state.visibleRange = {
        start,
        end
      };
    },
    setFilter(state, action) {
      const { filters } = action.payload;
      state.appliedFilter = filters;
    }
  }
});

export const reducer = slice.reducer;

export const setFilters = filters => async dispatch => {
  dispatch(slice.actions.setFilter({ filters }));
};

export const getEvents = (order, operatorId, dateLt, dateGt) => async dispatch => {
  let events = await queryEvents(order?.id, operatorId, dateLt, dateGt);
  dispatch(slice.actions.getEvents({ events }));
};

export const createEvent = (data, onSuccess = () => {}, onError = () => {}) => async dispatch => {
  worksServices.add(
    data,
    docRef => {
      onSuccess(docRef);
    },
    onError
  );
};

export const selectEvent = eventId => async dispatch => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (event, data, onSuccess) => async dispatch => {
  await worksServices.update(event.id, data, onSuccess, e => console.log(e));
  worksServices.get(event.id, response => {
    dispatch(slice.actions.updateEvent(response));
  });
};

export const deleteEvent = eventId => async dispatch => {
  await worksServices.delete(
    eventId,
    () => {},
    e => console.log(e)
  );
  dispatch(slice.actions.deleteEvent({ eventId }));
};

export const selectRange = (start, end) => dispatch => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime()
    })
  );
};

export const viewRange = (start, end) => dispatch => {
  dispatch(
    slice.actions.viewRange({
      start: start.getTime(),
      end: end.getTime()
    })
  );
};

export const openModal = () => dispatch => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => dispatch => {
  dispatch(slice.actions.closeModal());
};

const getColor = status => {
  switch (status) {
    case 'active':
      return colors.orange['700'];
    case 'finished':
      return colors.green['700'];
    case 'pending':
      return colors.grey['700'];
    default:
      return colors.blue['700'];
  }
};

const formatEvents = results => {
  results.forEach(event => {
    formatEvent(event);
  });
  return results;
};

const formatEvent = event => {
  event['start'] = event.start?.toDate();
  event['end'] = event.end?.toDate();
  event['color'] = event.order?.client?.calendarColor || getColor();
  event['title'] = event.operator.name + ' ' + event.specialty.title + ' ' + event.order.number;
  return event;
};

const queryEvents = async (orderId, operatorId, dateLt, dateGt) => {
  let events = [];
  if (orderId) {
    events = await worksServices.calendarQueryOrdered(orderId, operatorId, dateLt, dateGt, formatEvents, e =>
      console.log(e)
    );
  } else {
    events = await worksServices.calendarQueryAll(operatorId, dateLt, dateGt, formatEvents, e => console.log(e));
  }
  return events;
};

export default slice;
