import firestoreServices from './firestore.service';
import { getFirestore, collection, query, where, getDoc, getDocs, doc } from 'firebase/firestore';

const collectionName = 'notificationsHistory';
const notificationsHistoryFirestoreServices = firestoreServices(collectionName);

export default {
  ...notificationsHistoryFirestoreServices,
  get: (value, onResult) => {
    const q = query(
      collection(notificationsHistoryFirestoreServices.db, collectionName),
      where('usersNotRead', 'array-contains', value)
    );
    return getDocs(q).then(async queryResult => onResult(queryResult.docs));
  },
  setRead: (id, value, onResult) => {
    notificationsHistoryFirestoreServices.update(id, value, onResult);
  },
  deleteNotification: (id, value, onResult) => {
    notificationsHistoryFirestoreServices.update(id, value, onResult);
  }
};
