import React, { useRef, useState } from 'react';
import { Settings as SettingsIcon } from 'react-feather';
import { Box, Button, IconButton, Popover, SvgIcon, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { THEMES, WORKING_HOURS } from 'src/constants';
import useSettings from 'src/hooks/useSettings';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Ajustes">
        <IconButton color="inherit" onClick={handleOpen} ref={ref}>
          <SvgIcon fontSize="small">
            <SettingsIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Select
          value={values.theme}
          label="Tema"
          fullWidth
          onChange={event => handleChange('theme', event.target.value)}
        >
          {THEMES.map(theme => (
            <MenuItem key={theme} value={theme}>
              {theme}
            </MenuItem>
          ))}
        </Select>
        {WORKING_HOURS && (
          <>
            <Typography variant="h4" color="textPrimary">
              Horario laboral
            </Typography>
            <Box mt={2}>
              <TextField
                fullWidth
                label="Apertura"
                name="workingHoursFrom"
                onChange={event => handleChange('workingHoursFrom', event.target.value)}
                value="9:00"
                variant="outlined"
              ></TextField>
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                label="Cierre"
                name="workingHoursTo"
                onChange={event => handleChange('workingHoursTo', event.target.value)}
                value="17:00"
                variant="outlined"
              ></TextField>
            </Box>
          </>
        )}
        <Box mt={2}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleSave}>
            Guardar configuración
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default Settings;
