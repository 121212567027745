import {
  getMessaging,
  getToken as getTokenF,
  deleteToken as deleteTokenF,
  onMessage as onMessageF
} from 'firebase/messaging';
import axios from 'axios';
import userNotificationsServices from './userNotifications.services';
import { firebaseProject } from 'src/lib/firebaseProject';

export const getToken = userId => {
  try {
    const messaging = getMessaging(firebaseProject);

    getTokenF(messaging, {
      vapidKey: 'BLMy9VopQ9pzo0CkN_oOl9u9qIDTqZ1AevTFHDRz9EcJj01vCzUpPXRxvkEA915vu9Ch6_9vV1ToKt7NmODTxvo'
    })
      .then(async currentToken => {
        const existsToken = queryResult => {
          if (queryResult.docs.length < 1) registerToken(currentToken, userId);
        };

        if (currentToken) {
          console.log(currentToken);
          await userNotificationsServices.where('notificationTokens', 'array-contains', currentToken, existsToken);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch(err => {
        console.log('An error occurred while retrieving token. ', err);
      });
  } catch (error) {
    console.error(error);
  }
};

export const deleteToken = () => {
  const messaging = getMessaging(firebaseProject);

  // [START messaging_delete_token]
  deleteTokenF(messaging)
    .then(() => {
      console.log('Token deleted.');
      // ...
    })
    .catch(err => {
      console.log('Unable to delete token. ', err);
    });
};

export const receiveMessage = onNotificationReceived => {
  const messaging = getMessaging(firebaseProject);

  onMessageF(messaging, payload => {
    if (payload.data['title'] !== undefined) {
      const notification = {
        id: payload['fcmMessageId'],
        bodyMessage: {
          title: payload.data['title'],
          body: payload.data['body']
        }
      };

      onNotificationReceived();
    }
  });
};

const registerToken = (token, userId) => {
  const data = {
    token: token,
    userId: userId
  };
  axios
    .post('https://europe-west3-consamare-dev.cloudfunctions.net/add-new-device', data)
    .then(function(response) {
      console.log(response.data);
    })
    .catch(function(error) {
      console.log(error);
    });
};
