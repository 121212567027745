import firestoreServices from 'src/services/firestore.service';
import rolesServices from 'src/services/roles.services';

const usersFirestoreServices = firestoreServices('users');

export default {
  ...usersFirestoreServices,
  add: (data, onSuccess, onError = _ => {}) => {
    if (typeof data.rol === 'string' || data.rol instanceof String) {
      data.rolId = data.rol;
      data.rol = rolesServices.docRef(data.rol);
    }
    return usersFirestoreServices.add({ ...data, isActive: true }, onSuccess, onError);
  },
  update: (id, data, onSuccess, onError = _ => {}) => {
    if (typeof data.rol === 'string' || data.rol instanceof String) {
      data.rolId = data.rol;
      data.rol = rolesServices.docRef(data.rol);
    }
    return usersFirestoreServices.update(id, data, onSuccess, onError);
  },
  active: (id, onSuccess = _ => {}, onError = _ => {}) =>
    usersFirestoreServices.update(id, { isActive: true }, onSuccess, onError),
  close: (id, onSuccess = _ => {}, onError = _ => {}) =>
    usersFirestoreServices.update(id, { isActive: false }, onSuccess, onError),
  whereSpecialty: (specialtyId, onSuccess = _ => {}, onError = _ => {}) =>
    usersFirestoreServices.whereActived('specialties', 'array-contains', specialtyId, onSuccess, onError)
};
