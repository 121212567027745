import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useLocation, matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles(theme => ({
  active: {
    '& span': {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightMedium
    },
    '& svg': {
      color: theme.palette.secondary.main
    }
  }
}));

const NavLeafItem = ({ depth, href, icon: Icon, title }) => {
  const classes = useStyles();
  const location = useLocation();
  const leafSelected = Boolean(matchPath(location.pathname, { path: href, exact: true }));

  let paddingLeft = 2 + 2 * depth;

  return (
    <ListItem disablePadding disableGutters key={title}>
      <ListItemButton
        className={leafSelected ? classes.active : ''}
        selected={leafSelected}
        sx={{ paddingLeft }}
        component={RouterLink}
        to={href}
      >
        {Icon && (
          <ListItemIcon sx={{ minWidth: 35 }}>
            <Icon size="20" />
          </ListItemIcon>
        )}
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};

NavLeafItem.propTypes = {
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string.isRequired
};

NavLeafItem.defaultProps = {
  open: false
};

export default NavLeafItem;
