import firestoreServices from 'src/services/firestore.service';
import clientsServices from 'src/services/clients.services';
import firebase from 'firebase/compat/app';
import worksServices from './works.services';

const ordersFirestoreServices = firestoreServices('orders');

export default {
  ...ordersFirestoreServices,
  add: (data, onSuccess, onError = _ => {}) => {
    if (typeof data.client.id === 'string' || data.client.id instanceof String) {
      data.client = clientsServices.docRef(data.client.id);
    }
    data.createdAt = firebase.firestore.Timestamp.fromDate(new Date());
    return ordersFirestoreServices.add({ ...data, isActive: true }, onSuccess, onError);
  },
  update: (id, data, onSuccess, onError = _ => {}) => {
    const onUpdated = () => {
      onSuccess();
      if (data.status === 'CANCELED') {
        worksServices.withNoStatus(id, 'CANCELED', result => {
          let promValues = [];
          result.forEach((doc, _) => {
            promValues.push(
              new Promise(res => {
                worksServices.update(
                  doc.id,
                  {
                    status: 'CANCELED'
                  },
                  () => {
                    res(true);
                  }
                );
              })
            );
          });
          Promise.all(promValues).then(result => console.log('works updated'));
        });
      }
    };
    if (typeof data.client.id === 'string' || data.client.id instanceof String) {
      data.client = clientsServices.docRef(data.client.id);
    }
    return ordersFirestoreServices.update(id, data, onUpdated, onError);
  }
};
