import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Box, Button, Link, Portal, Typography, useTheme } from '@mui/material';

const CookiesNotification = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    Cookies.set('consent', 'true', { expires: 30 });
    setOpen(false);
  };

  useEffect(() => {
    const consent = Cookies.get('consent');

    if (!consent) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <div
        style={{
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
          maxWidth: 600,
          position: 'fixed',
          bottom: 0,
          left: 0,
          margin: theme.spacing(3),
          padding: theme.spacing(3),
          outline: 'none',
          zIndex: 2000
        }}
      >
        <Typography variant="body1" color="inherit">
          Utilizamos cookies para asegurarnos de que le damos la mejor experiencia en nuestro sitio web. Lea nuestra{' '}
          <Link component="a" color="inherit" underline="always" href="/privacy-policy" target="_blank">
            política de privacidad
          </Link>
          .
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button onClick={handleClose} variant="contained">
            Aceptar
          </Button>
        </Box>
      </div>
    </Portal>
  );
};

export default CookiesNotification;
