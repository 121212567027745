export const APP_VERSION = '3.3.1';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEME_DARK = 'DARK';
export const THEME_LIGHT = 'LIGHT';
export const THEME_UNICORN = 'UNICORN';

export const THEMES = [THEME_DARK, THEME_LIGHT];

export const WORKING_HOURS = false;
export const REQUIRED_FILES = false;
export const ONLY_LAST_WORK_ENABLED = true;

export const ADMIN = 'ADMIN';
export const OPERATOR = 'OPERATOR';
export const MANAGER = 'MANAGER';

export const WATCH = 'GUARDIA';
export const NORMAL = 'NORMAL';

export const OPEN = 'OPEN';
export const PLANNED = 'PLANNED';
export const CANCELED = 'CANCELED';
export const COMPLETED = 'COMPLETED';
export const DELAYED = 'DELAYED';
export const PENDING = 'PENDING';
export const WORKING = 'WORKING';
export const OFFICE = 'OFFICE';

export const ORDERS_STATES = [OPEN, CANCELED, COMPLETED];
export const ORDERS_COLUMNS_STATES = [OPEN, COMPLETED, PENDING, CANCELED, DELAYED, PLANNED, WORKING];

export const TASKS_STATUS = [OPEN, COMPLETED, PENDING, OFFICE, CANCELED];
